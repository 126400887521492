import { useField } from "formik";
import { Done } from "../Icons";
import "./FormInput.scss";

export interface CheckboxInputProps {
  name: string;
  label: string;
}
export default function CheckboxInput({ label, ...props }: CheckboxInputProps) {
  const [field, meta] = useField(props);
  return (
    <div>
      <label>
        <input {...field} {...props} type="checkbox" />
        {field.value === true ? (
          <Done fill="var(--alert-success)" className="grey" />
        ) : (
          <Done fill="none" className="grey" />
        )}
        {label}
      </label>

      {meta.touched && meta.error ? (
        <p className="input-label-error">{meta.error}</p>
      ) : null}
    </div>
  );
}
