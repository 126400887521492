export const scholarshipData = [
        {
                key: 1,
                label: 'Yes',
                value: true
        },
        {
                key: 2,
                label: 'No',
                value: false
        }
];

export const genderData = [
        {
                key: 1,
                label: 'Female',
                value: "FEMALE"
        },
        {
                key: 2,
                label: 'Male',
                value: 'MALE'
        },
        {
                key: 3,
                label: 'Unknown',
                value: 'UNKNOWN'
        }
];

export const statusData = [
        {
                key: 1,
                label: 'NEW',
                value: 'NEW',
        }, {
                key: 2,
                label: 'Promising',
                value: 'PROMISING_SENT'
        }, {
                key: 3,
                label: 'Challenge Sent',
                value: 'CHALLENGE_SENT',
        }, {
                key: 4,
                label: 'Q&A Sent',
                value: 'QN_ASENT',
        }, {
                key: 5,
                label: 'Invitation Sent',
                value: 'INVITATION_SENT',
        }, {
                key: 6,
                label: 'Interview Scheduled',
                value: 'INTERVIEW_SCHEDULED',
        }, {
                key: 7,
                label: 'Interview Done',
                value: 'INTERVIEW_DONE',
        }, {
                key: 8,
                label: 'Accepted',
                value: 'ACCEPTED',
        }, {
                key: 9,
                label: 'Form Filled',
                value: 'FORM_FILLED',
        },  {
                key: 10,
                label: 'Participant',
                value: 'PARTICIPANT',
        }, {
                key: 11,
                label: 'Rejected',
                value: 'REJECTED',
        }, {
                key: 12,
                label: 'Waiting List Sent',
                value: 'WAITING_LIST_SENT',
        }, {
                key: 13,
                label: 'Withdrawn',
                value: 'WITHDRAWN',
        }, {
                key: 14,
                label: 'Duplicated',
                value: 'DUPLICATED',
        }
]
