import { set_comment } from "./Constants";

const initialValues = {
        comment: ""
}

export default function CommentReducer(
        state = initialValues,
        { type, payload }: any
) {
        switch (type) {
                case set_comment:
                        return {
                                ...state,
                                comment: [payload],
                        };
                default:
                        return state;
        }
}
