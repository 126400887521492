// @ts-nocheck
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  TableCell,
  TableRow,
} from "../../reusableComponents/Table";
import { load_applicant_details } from "../Redux/Constants";

export default function ApplBatchRow(props: any) {
  const {
    id,
    name,
    email,
    onelogin,
    oneloginStatus,
    batch,
    track,
    gender,
    program,
    location,
    streetNumber,
    addressSuffix,
    postcode,
    city,
    country,
    accountHolder,
    bankName,
    iban,
    bic,
    shirtSize,
    shirtStyle,
    foodIntolerances,
    scholarshipType,
  } = props.applicant;

  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);

  // To be refactored
  let programLogo;
  if (program?.id === "adeade6a-dd0b-4e98-b05e-51b2e3a9e66d") {
    programLogo = <img src="https://i.ibb.co/jbm3rW7/Digital-Schmiede.png" alt="Program" style={{ maxHeight: "30px", zIndex: "-1" }} />
  } else if (program?.id === "adeade6a-dd0b-4e98-b05e-51b2e3a9e66f") {
    programLogo = <img src="https://i.ibb.co/XzFkLtH/Screenshot-2022-07-27-at-12-50-36.png" alt="Program" style={{ maxHeight: "30px", zIndex: "-1" }} />
  }

  const cells = [
    name,
    email,
    onelogin,
    oneloginStatus,
    batch,
    track,
    gender,
    programLogo,
    location,
    streetNumber,
    postcode,
    city,
    country,
    scholarshipType,
    accountHolder,
    bankName,
    iban,
    bic,
    shirtSize + " & " + shirtStyle,
    foodIntolerances,
  ];
  const [hover, setHover] = useState(false);
  const applicantUrl = `/applicant/${batch}/${id}`;

  const details = (id) => {
    return fetch(`/api/raw/persons/` + id, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(async details => {
      const applicantDetails = await details.json()
      dispatch({
        type: load_applicant_details,
        payload: applicantDetails
      })
    }).catch(error => {
      console.log(error);
    });
  };

  return (
    <TableRow
      onMouseEnter={() => setHover(true)}
      onClick={() => { setHover(true); details(id) }}
      onMouseLeave={() => setHover(false)}
      hover={hover}
    >
      {cells.map((cell, index) => (
        <TableCell key={index} align="left" component="td">
          <Link to={applicantUrl} state={{ from: "batchpage" }}>{cell}</Link>
        </TableCell>
      ))}
    </TableRow>
  );
}
