import { combineReducers } from 'redux'
import AcceptanceFormReducer from '../Components/Redux/AcceptanceFormReducer'
import AuthReducer from '../Components/Redux/AuthReducer';
import CommentReducer from '../Components/Redux/CommentReducer';
import FilterApplicantsReducer from '../Components/Redux/FilterApplicantsReducer';
import AllApplicantsReducer from '../Components/Redux/AllApplicantsReducer';
import BatchesReducer from '../Components/Redux/BatchesReducer';
import storage from 'redux-persist/lib/storage'
import { clear_auth } from '../Components/Redux/Constants';
import TrackInstancesReducer from '../Components/Redux/TrackInstancesReducer';
import ProgramsReducer from '../Components/Redux/ProgramesReducer';
import TracksReducer from '../Components/Redux/TracksReducer';


const appReducer = combineReducers({
        acceptanceForm: AcceptanceFormReducer,
        filterApplicants: FilterApplicantsReducer,
        applicants: AllApplicantsReducer,
        batches: BatchesReducer,
        programs: ProgramsReducer,
        trackInstances: TrackInstancesReducer,
        tracks: TracksReducer,
        comment: CommentReducer,
        auth: AuthReducer
})

const rootReducer = (state, action) => {
        if (action.type === clear_auth) {
                // for all keys defined in your persistConfig(s)
                storage.removeItem('persist:root')
                // storage.removeItem('persist:otherKey')
                return appReducer(undefined, action);
        }
        return appReducer(state, action);
};
export default rootReducer;