import { select_track, unselect_track, select_batch, unselect_batch, select_status, unselect_status, clear_batch, clear_status, clear_track} from "./Constants";

const initialValues = {
        batch: [17,18],
        track: ["SE", "PMC", "PM", "AI", "AC", "IXD"],
        status: ["NEW"], 
        updated: false,
}

export default function FilterApplicantsReducer(
        state = initialValues,
        { type, payload }: any
) {  
        switch (type) {
                case select_track:
                        if (state.track.includes(payload))
                        return {...state}
                        else
                        return {
                                ...state,
                                track: [...state.track, payload],
                                updated: true,
                        };
                case unselect_track:
                        return {
                                ...state,
                                track: [...state.track.filter((track) => track !== payload)],
                                updated: true,
                        };
                
                case clear_track: 
                        return {
                                ...state,
                                track:[],
                                updated: true,
                        };
                case select_batch:
                        if (state.batch.includes(payload))
                        return {...state}
                        else
                        return {
                                ...state,
                                batch: [...state.batch, payload],
                                updated: true,
                        };
                case unselect_batch:
                        return {
                                ...state,
                                batch: [...state.batch.filter((batch) => batch !== payload)],
                                updated: true,
                        };

                case clear_batch: 
                        return {
                        ...state,
                        batch:[],
                        updated: true,
                    };
                case select_status:
                        if (state.status.includes(payload))
                        return {...state}
                        else
                        return {
                                ...state,
                                status: [...state.status, payload],
                                updated: true,
                        };
                case unselect_status:
                        return {
                                ...state,
                                status: [...state.status.filter((status) => status !== payload)],
                                updated: true,
                        };
                
                case clear_status: 
                        return {
                                ...state,
                                status:[],
                                updated: true,
                        };
                default:
                        return state;
        }
}
