import React from "react";
import "./Button.scss";

export interface ButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  primary?: boolean;
  backgroundColor?: string;
  color?: string;
  size?:  "small" | "medium" | "large";
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  type="button",
  primary = false,
  backgroundColor,
  color,
  size = "medium",
  label,
  disabled = false,
  ...props
}) => {
  const mode = primary ? "button--primary" : "button--secondary";
  return (
    <button
      type={type}
      disabled={disabled}
      className={["button", `button--${size}`, mode].join(" ")}
      style={{ backgroundColor, color }}
      {...props}
    >
      {label}
    </button>
  );
};
