import React from "react";
import cn from "classnames";
import "./CircularLoading.scss";

export interface CircularLoadingProps {
  color?: string;
  size?: any;
  className?: string;
}

export const CircularLoading: React.FC<CircularLoadingProps> = ({
  color = "black",
  size = "100",
  className,
}) => {
  const classNames = cn("dual-ring", {
    [`dual-ring--${className}`]: className,
  });
  return (
    <div className="circular-loading" style={{ width: size, height: size }}>
      <div className={`${classNames}`}>
        <div
          className="dual-ring-after"
          style={{
            borderColor: `${color} transparent`,
            borderWidth: size * 0.1,
            width: size * 0.7 - 6,
            height: size * 0.7 - 6,
          }}
        ></div>
      </div>
    </div>
  );
};
