import CsvDownload from "react-json-to-csv";

export default function DownloadCSV({ data, filename }: any) {
  return (
    <CsvDownload
      data={data}
      filename={filename}
      className="new-button"
    >
      Download CSV
    </CsvDownload>
  );
}