import React from "react";
import "./Image.scss";
import cn from "classnames";

export interface ImageProps {
  alt: string;
  src: string;
  size?: "xsmall" | "small" | "medium" | "large" | "xlarge";
  align?: "right" | "center" | "left";
  round?: boolean
}

export const Image: React.FC<ImageProps> = ({
  alt,
  src,
  size = "medium",
  align = "center",
  round = false,
  ...props
}) => {
  const modealign = align ? "image--centered" : "image";
  const moderound = round ? "image--round" : "image";
  const classNames = cn({ [`image--${align}`]: align});
  return (
    <div className={classNames}>
      <img
        src={src}
        alt={alt}
        className={[`image--${size}`, modealign, moderound].join(" ")}
        {...props}
      />
    </div>
  );
};
