import { Grid } from "../../reusableComponents";
import SimpleSelectInput from "../../reusableComponents/FormInput/SimpleSelectInput";
import { shirtSize, shirtStyle } from "./formData";

export default function AcceptanceFormShirt(props) {
  return (
    <div style={{ width: "100%" }} >
      {props.hideText ? null : (
        <p>
        Surprise, surprise! Please select the size according to the European
        size system and your preferred style.
        </p>)}
      <Grid container spacing="sm" alignItems="center">
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <SimpleSelectInput
            name="onboardingDetails.shirtSize"
            label="Shirt Size"
            options={shirtSize}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <SimpleSelectInput
            name="onboardingDetails.shirtStyle"
            label="Shirt Style"
            options={shirtStyle}
          />
        </Grid>
      </Grid>
    </div>
  );
}
