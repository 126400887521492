import React from "react";
import { IconsProps } from "./Icons.stories";

export const Done: React.FC<IconsProps> = ({
  fill = "var(--black)",
  className = "",
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9999 16.2L4.7999 12L3.3999 13.4L8.9999 19L20.9999 6.99998L19.5999 5.59998L8.9999 16.2Z"
        fill={fill}
      />
    </svg>
  );
};
