import { useField } from "formik";
import "./FormInput.scss";

export interface TextAreaInputProps {
  name: string;
  label: string;
  rows: number;
  children?: any;
}
export default function TextAreaInput({ label, children, ...props }: TextAreaInputProps) {
  const [field, meta] = useField(props);
  const className =
    meta.touched && meta.error ? "form-input-text-error" : "form-input-text";

  return (
    <div className="input-field">
      <textarea {...field} {...props} className={className}></textarea>{children}
      <label className="text-label">{label}</label>
      {meta.touched && meta.error ? (
        <p className="input-label-error">{meta.error}</p>
      ) : null}
    </div>
  );
}
