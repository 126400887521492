import "./Navbar.scss";
import Logo from "../../Assets/dps-icon.svg";
import { Image, Grid, Alert } from "../../reusableComponents";
import { LoadUser } from "../../GraphQL/Queries";
import { useQuery } from "@apollo/client";
import Logout from "../Auth/Logout";
import { Link, NavLink } from "react-router-dom";
import { Menu } from "../../reusableComponents/Icons/Menu";
import { useRef } from "react";
import { useDetectOutsideClick } from "./useDetectOutsideClick";

export default function Navbar() {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  const { loading, error, data } = useQuery(LoadUser);
  if (loading) return null;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  return (
    <div className={["navbar", "navbar--sticky"].join(" ")}>
      <Grid container spacing="sm" alignItems="center">
        <Grid item xs={1} sm={2} md={2} lg={1}>
          <Image src={Logo} alt="logo" size="small" align="right" />
        </Grid>

        <Grid item xs={3} sm={4} md={4} lg={7}>
          <Link to="/all-applicants">
            <h4 className="bold uppercase m-a ">Recruitinator</h4>
          </Link>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} justifyContent="flex-end">
          <Image
            alt="user"
            src={data.user.photo}
            round={true}
            align="right"
            size="small"
          />
        </Grid>
        <Grid item xs={1} sm={2} md={2} lg={1}>
          <h6 className="bold">{data.user.name}</h6>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <div className="container">
            <div className="menu-container">
              <button onClick={onClick} className="menu-trigger">
                <Menu fill="none" />
              </button>
              <nav
                ref={dropdownRef}
                className={`menu ${isActive ? "active" : "inactive"}`}
              >
                <ul>
                  <li>
                    <NavLink to="/all-applicants">Applicants</NavLink>
                  </li>
                  <li>
                    <NavLink to="/batches">Batches</NavLink>
                  </li>
                  <li>
                    <Logout />
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
