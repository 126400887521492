import { load_track_instances } from "./Constants";

const initialValues = null;

export default function TrackInstancesReducer(
        state = initialValues,
        { type, payload }: any
) {  
        switch (type) {
                case load_track_instances:
                        return payload;
                default:
                        return state;
        }
}
