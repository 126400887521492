import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import config from "./config";

const firebaseConfig = config.firebaseConfig;

firebase.initializeApp(firebaseConfig);
export const storage = firebase.storage();
export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
