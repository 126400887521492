import { MouseEvent } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { clearAuth } from "../Redux/Actions";

export default function Logout() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const signOut = (event: MouseEvent) => {
    event.preventDefault();
    auth.signOut();
    dispatch(clearAuth());
    navigate("/login");
  };

  return (
    <a onClick={signOut} href="/login" className="logout">
      Logout
    </a>
  );
}
