import CheckboxInput from "../../reusableComponents/FormInput/CheckboxInput";

export default function AcceptanceFormRights() {
  return (
    <>
      <p>
        <b>
          Responsibility to fulfill obligations on information and data privacy in projects at Digital Product School
        </b>
        <br />For the collection, storage, and processing of personal and non-personal data within the scope of the projects of the Digital Product School, I as a participant may only use systems, tools, programs, and providers that have been released for use by UnternehmerTUM GmbH (whitelisting).
        <br /> I undertake to delete the websites and social media accounts after leaving the Digital Product School. If personal data were collected from third parties in the course of user tests and product development, I am obliged to delete them completely after leaving the Digital Product School. <br />
        Upon leaving the Digital Product School, I am responsible for all internet or social media presences created in the course of the program as well as for all data collected. <br /> <br />
        <b>Image rights in projects at Digital Product School</b> <br />
        If I as a participant of Digital Product School create publicly accessible websites or accounts on social media networks, e.g. to validate user problems or product ideas or to test them, within the scope of my work at the Digital Product School for myself or for my team, I undertake to only use pictures that I have the rights to use in this case and that do not violate the personal rights of others. <br />
        <br />
        <b>Please note: </b>
        <br />
        We hereby inform you that all photo and video footage taken within the
        Digital Product School and its events can be used for on- and offline
        marketing- and public relations activities by the UnternehmerTUM GmbH.
        We will post some photos on our social media channels and websites.{" "}
        <br /> <br /> Further information on how we process personal data can be
        found in the {" "}
        <a
          href="https://digitalproductschool.io/privacy-policy/utum/"
          className="u-link"
          target="_blank"
          rel="noreferrer noopener"
        >
          <span>privacy policy</span>
        </a>
        {" "} of Digital Product School and it’s parent company UnternehmerTUM GmbH.
      </p>
      <CheckboxInput
        label=" I hereby consent to the processing of the above-mentioned data for the purpose of participating in the program of the Digital Product School (DPS)."
        name="onboardingDetails.consentData"
      />
      <br />
      <CheckboxInput
        label=" I wish to receive information about alumni program events on a regular basis. I hereby consent to the processing of my contact data for the purpose of being contacted by the DPS as part of the alumni program's events."
        name="onboardingDetails.consentAlumni"
      />
      <br />
    </>
  );
}
