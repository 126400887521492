
import { Button } from "../../reusableComponents";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createForm } from "../Redux/Actions";
import { validationSchema } from "./formData";
import AcceptanceFormAddress from "./Address";
import AcceptanceFormBank from "./Bank";
import AcceptanceFormFood from "./FoodIntolerances";
import AcceptanceFormShirt from "./Shirt";
import AcceptanceFormRights from "./Rights";
import { RootState } from "../..";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "./Header";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';

export default function AcceptanceForm() {

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  const [id, setId] = useState("");
  const [batch, setBatch] = useState("");
  const [fullName, setFullName] = useState("");

  const valuesAfter = useSelector((state: RootState) => state.acceptanceForm);

  delete valuesAfter.consentAlumni;
  delete valuesAfter.consentData;

  const getPersonData = (token) => {
    fetch(`/api/raw/participants/accepted/` + token, {
      method: 'GET',
    }).then(async response => {
      const acceptedDto = await response.json()
      setId(acceptedDto.id);
      setBatch(acceptedDto.batch);
      setFullName(acceptedDto.fullName);
      valuesAfter.email = acceptedDto.email;
      valuesAfter.program = acceptedDto.program;
      valuesAfter.track = acceptedDto.track;

      if (acceptedDto.onboardingDetails) {
        dispatch(createForm(acceptedDto.onboardingDetails));
      }
    })
      .catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {
    getPersonData(searchParams.get("token"));
  }, []);

  const updateForm = (data, id) => {
    fetch(`/api/raw/onboardingDetails/` + id, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(async response => {
      const onboardingDetails = await response.json()
      dispatch(createForm(onboardingDetails));
      toast.success("Success!");
    }).catch(error => {
      console.log(error);
      toast.error("Something went wrong, try again!");
    })
  }

  const submitForm = (data) => {
    fetch(`/api/raw/onboardingDetails/`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(async response => {
      const onboardingDetails = await response.json()
      dispatch(createForm(onboardingDetails));
      toast.success("Success!");
    })
      .catch(error => {
        console.log(error);
        toast.error("Something went wrong, try again!");
      })
  }

  return (
    <div className="application-form">
      <Formik
        initialValues={valuesAfter}
        validationSchema={validationSchema}
        onSubmit={(values: any) => {
          values.onboardingDetails["personId"] = id;
          (values.onboardingDetails.id) ? updateForm(values.onboardingDetails, values.onboardingDetails.id) : submitForm(values.onboardingDetails)
          navigate("/form/thank-you")
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form>
            <Header />
            <h1 className="center">
              Welcome to Digital Product School Batch#
              {batch}!
            </h1>
            <br />
            <p>
              Hello {fullName}! <br />
            </p>
            <AcceptanceFormAddress />
            <AcceptanceFormBank />
            <AcceptanceFormFood />
            <AcceptanceFormShirt />
            <AcceptanceFormRights />
            <div style={{ textAlign: "center" }}>
              <Button
                label="Submit"
                size="small"
                type="submit"
                disabled={!isValid || !dirty || isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
      <br />
    </div>
  );
}
