// @ts-nocheck
import { Button, Grid } from "../../reusableComponents";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import TextInput from "../../reusableComponents/FormInput/TextInput";
import SelectInput from "../../reusableComponents/FormInput/SimpleSelectInput";
import {
  genderData,
  scholarshipData,
  statusData,
} from "../AllApplicantsPage/filterData";
import AcceptanceFormAddress from "../AcceptanceForm/Address";
import AcceptanceFormBank from "../AcceptanceForm/Bank";
import AcceptanceFormFood from "../AcceptanceForm/FoodIntolerances";
import AcceptanceFormShirt from "../AcceptanceForm/Shirt";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';


export default function EditApplicantForm() {

  let params = useParams();
  const selectedApplicant = useSelector((state: RootState) => state.applicants.selectedApplicant);
  const tracks = useSelector((state: RootState) => state.tracks);
  const batches = useSelector((state: RootState) => state.batches);
  const programs = useSelector((state: RootState) => state.programs)
  const token = useSelector((state: RootState) => state.auth.token);


  const editApplicant = (data, id) => {

    if (typeof data.trackInstance.batch === 'object') {
      data.trackInstance.batch = data.trackInstance.batch.id
    }
    if (typeof data.trackInstance.track === 'object') {
      data.trackInstance.track = data.trackInstance.track.id
    }

    fetch(`/api/raw/trackInstances/find_by_track_&_batch/` + data.trackInstance.batch + "/" + data.trackInstance.track, {
      method: 'GET'
    }).then(async response => {
      const trackInstance = await response.json()
      data.trackInstance = trackInstance
      return data
    }).then(dataWithTrackInstance => {
      fetch(`/api/raw/persons/` + id, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-type': 'application/json'
        },
        body: JSON.stringify(dataWithTrackInstance)
      }).then(async response => {
        response.status === 200 && toast.success("Success!");
      }).catch(error => {
        console.log(error)
        toast.error("Something went wrong, try again!");
      })
    })
  }

  return (
    <div className="applicant-page-wrapper ">
      <Formik
        initialValues={selectedApplicant}
        onSubmit={(values: any) => {
          editApplicant(values, params.id)
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form>
            <h1>Edit applicant</h1>
            <br />
            <Grid container spacing="sm" alignItems="center">
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextInput label="First Name" name="firstName" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextInput label="Last Name" name="lastName" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextInput label="Email" name="email" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <SelectInput
                  name="scholarship"
                  label="Scholarship"
                  options={scholarshipData}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <SelectInput
                  name="program.id"
                  label="Program"
                  options={programs.map(program => {
                    var programIds = {
                      "key": program.id,
                      "label": program.name,
                      "value": program.id,
                    }
                    return programIds
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <SelectInput name="trackInstance.track.id" label="Track" options={tracks.map(track => {
                  var trackIds = {
                    "key": track.id,
                    "label": track.name,
                    "value": track.id,
                  }
                  return trackIds
                })} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <SelectInput name="trackInstance.batch.id" label="Batch" options={batches.map(batch => {
                  var batchIds = {
                    "key": batch.id,
                    "label": batch.name,
                    "value": batch.id,
                  }
                  return batchIds
                })} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <SelectInput
                  name="gender"
                  label="Gender"
                  options={genderData}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <SelectInput
                  name="status"
                  label="Status"
                  options={statusData}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextInput label="Source" name="source" />
              </Grid>
              {selectedApplicant.onboardingDetailsId &&
                <>
                  <AcceptanceFormAddress hideText="true" />
                  <AcceptanceFormBank hideText="true" />
                  <AcceptanceFormFood hideText="true" />
                  <AcceptanceFormShirt hideText="true" />
                </>
              }
            </Grid>
            <div style={{ textAlign: "center" }}>
              <Button
                label="Submit"
                size="small"
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
      <br />
    </div>
  );
}
