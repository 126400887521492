export default function Header() {
        return (
                <div className="acceptance-form-header">
                        <img
                                src="https://i.ibb.co/FDFcLTY/Unternehmertum.png"
                                alt="Utum"
                                style={{ maxWidth: "100px", width: "70%" }}
                        />
                        <img
                                src="https://i.ibb.co/8zxxpxq/dps.png"
                                alt="DPS"
                                style={{ maxWidth: "150px", width: "70%" }}
                        />
                </div>
        );
}
