import "./FormInput.scss";
import { useField } from "formik";

export interface SelectInputProps {
        name: string;
        label: string;
        options: any;
}

export default function SimpleSelectInput({ label, ...props }: SelectInputProps) {
        const [field, meta] = useField(props);
        const className =
                meta.touched && meta.error ? "form-input-text-error" : "form-input-text";
        return (
                <div className="input-field">
                        <select {...field} {...props} className={className}>
                        <option value="" selected disabled></option>
                                {props.options.map((obj: any) => {
                                        return (
                                                <option value={obj.value} key={obj.value}>
                                                        {obj.label}
                                                </option>
                                        );
                                })}
                        </select>
                        <label className="text-label">{label}</label>
                        {meta.touched && meta.error ? (
                                <p className="input-label-error">{meta.error}</p>
                        ) : null}
                </div>
        );
}