import { create_form, select_batch, unselect_batch, select_track, unselect_track, select_status, unselect_status, set_comment, set_auth, clear_auth, clear_status, clear_track, clear_batch, filter_applicants, load_batches, load_applicant_details, load_programs, load_track_instances, load_tracks } from "./Constants";

export const createForm = (payload: any)  => dispatch=> {
  dispatch({
    type: create_form,
    payload: payload,
  });
}

export const selectBatch = (payload: any)  => dispatch=> {
  dispatch({
    type: select_batch,
    payload: payload,
  });
}

export const unselectBatch = (payload: any)  => dispatch=> {
  dispatch({
    type: unselect_batch,
    payload: payload,
  });
}


export const selectTrack = (payload: any) => dispatch => {
  dispatch({
    type: select_track,
    payload: payload,
  });
}

export const unselectTrack = (payload: any) => dispatch => {
  dispatch({
    type: unselect_track,
    payload: payload,
  });
}

export const selectStatus = (payload: any)  => dispatch => {
  dispatch({
    type: select_status,
    payload: payload,
  });
}

export const unselectStatus = (payload: any) => dispatch  => {
  dispatch({
    type: unselect_status,
    payload: payload,
  });
}

export const setComment = (payload: any)  => dispatch => {
  dispatch({
    type: set_comment,
    payload: payload,
  });
}

export const setAuth = (payload: any)  => dispatch => {
  dispatch({
    type: set_auth,
    payload: payload,
  });
}


export const clearAuth = ()  => dispatch => {
  dispatch({
    type: clear_auth,
  });
}


export const clearBatch = ()  => dispatch => {
  dispatch({
    type: clear_batch
  });
}

export const clearTrack = ()  => dispatch => {
  dispatch({
    type: clear_track
  });
}

export const clearStatus = ()  => dispatch => {
  dispatch({
    type: clear_status
  });
}


export const filterApplicants = (payload: any)  => dispatch => {
  dispatch({
    type: filter_applicants,
    payload: payload
  });
}


export const loadBatches = (payload: any)  => dispatch => {
  dispatch({
    type: load_batches,
    payload: payload
  });
}

export const loadApplicantDetails = (payload: any)  => dispatch => {
  dispatch({
    type: load_applicant_details,
    payload: payload
  });
}

export const loadPrograms = (payload: any)  => dispatch => {
  dispatch({
    type: load_programs,
    payload: payload
  });
}


export const loadTrackInstances = (payload: any)  => dispatch => {
  dispatch({
    type: load_track_instances,
    payload: payload
  });
}


export const loadTracks = (payload: any)  => dispatch => {
  dispatch({
    type: load_tracks,
    payload: payload
  });
}