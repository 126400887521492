import React, { useEffect } from "react";
import {
  ApolloProvider,
} from "@apollo/client";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { Login } from "./Components/Auth/Login";
import Form from "./Components/AcceptanceForm/Form";
import ApplicantPage from "./Components/ApplicantPage/ApplicantPage";
import Navbar from "./Components/Navbar/Navbar";
import ApplicantsTable from "./Components/AllApplicantsPage/AllApplicantsPage";
import { auth } from "./firebase";
import AllBatchesPage from "./Components/AllBatchesPage/AllBatchesPage";
import BatchPage from "./Components/BatchPage/BatchPage";
import { CreateClient } from "./Client";
import { useDispatch } from "react-redux";
import { setAuth } from "./Components/Redux/Actions";
import { PrivateRoute } from "./Components/Auth/PrivateRoute";
import ThankYou from "./Components/AcceptanceForm/ThankYou";
import AddApplicantForm from "./Components/ApplicantForm/AddApplicantForm";
import EditApplicantForm from "./Components/ApplicantForm/EditApplicantForm";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface AppProps {
  ga4?: any;
}

function App(props: AppProps) {
  const dispatch = useDispatch();
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        dispatch(setAuth(token));
      }
    });
  }, []);
  const client = CreateClient();
  return (
    <ApolloProvider client={client}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate replace to="/all-applicants" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/form/thank-you" element={<ThankYou />} />
          <Route path="/form/" element={<Form />} />
          <Route
            path="/all-applicants"
            element={
              <PrivateRoute>
                <Navbar />
                <ApplicantsTable />
              </PrivateRoute>
            }
          />
          <Route
            path="/applicant/:batch/:id"
            element={
              <PrivateRoute>
                <Navbar />
                <ApplicantPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/batches"
            element={
              <PrivateRoute>
                <Navbar />
                <AllBatchesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/batch/:batch"
            element={
              <PrivateRoute>
                <Navbar />
                <BatchPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/all-teams"
            element={
              <PrivateRoute>
                <Navbar />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-applicant"
            element={
              <PrivateRoute>
                <Navbar />
                <AddApplicantForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-applicant/:batch/:id"
            element={
              <PrivateRoute>
                <Navbar />
                <EditApplicantForm />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
