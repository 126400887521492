
import { useSelector } from "react-redux";
import { RootState } from "../..";
import "./ApplicantPage.scss";
import { useEffect, useState } from "react";
import DocumentFrame from "./DocumentFrame";

export default function ApplicantCV({ id }: any) {

    const [blobCV, setBlobCV] = useState<string>();
    const token = useSelector((state: RootState) => state.auth.token);
    const uri = "/api/raw/persons/" + id + "/cv"
    const request = new Request(uri,
        {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );

    useEffect(() => {
        fetch(request)
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                }
                throw new Error('Network response was not ok.');
            })
            .then((blob) => {
                const file = window.URL.createObjectURL(blob);
                const iframe = document.querySelector("iframe");
                if (iframe?.src) iframe.src = file;
                setBlobCV(file)
            })
            .catch((err) => {
                console.log(err)
            });
    }, [id]);

    return (
        <>
            <DocumentFrame data={blobCV} />
        </>
    );
}
