import { useLocation, useParams } from "react-router-dom";
import { Grid } from "../../reusableComponents";
import ApplicantInfo from "./ApplicantInfo";
import CommentList from "../Comment/CommentList";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import ApplicantCV from "./ApplicantCV";
import ApplicantCover from "./ApplicantCover";
import { load_applicant_details } from "../Redux/Constants";
import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'
import 'react-reflex/styles.css'
import AllApplicantsPage from "../AllApplicantsPage/AllApplicantsPage";
import BatchPage from "../BatchPage/BatchPage";

export default function ApplicantPage(props: any) {
  const params = useParams();
  const dispatch = useDispatch();
  const selectedApplicant = useSelector((state: RootState) => state.applicants.selectedApplicant);
  const token = useSelector((state: RootState) => state.auth.token);

  // to be refactored because its twice
  const details = (id) => {
    return fetch(`/api/raw/persons/` + id, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(async details => {
      const applicantDetails = await details.json()
      dispatch({
        type: load_applicant_details,
        payload: applicantDetails
      })
    }).catch(error => {
      console.log(error);
    });
  };

  useEffect(() => {
    details(params.id);
  }, []);

  const location = useLocation()

  return (
    <ReflexContainer orientation="vertical">

      <ReflexElement className="left-pane">
        <div className="pane-content">
          {location?.state?.from === "batchpage" ? <BatchPage batch={props.batchNumber} /> : <AllApplicantsPage />}
        </div>
      </ReflexElement>

      <ReflexSplitter />

      <ReflexElement className="right-pane">
        <div className="pane-content">
          <div className="applicant-page-wrapper">
            {selectedApplicant &&
              <Grid container spacing="sm" alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ApplicantInfo
                    data={selectedApplicant}
                    batchId={params.batch ? params.batch : ''}
                    applicantId={params.id ? params.id : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ApplicantCV id={selectedApplicant.id} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {selectedApplicant?.coverLetter ? <ApplicantCover id={selectedApplicant.id} /> : null}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <CommentList
                    batchId={params.batch}
                    applicantId={params.id} />
                </Grid>
              </Grid>}
          </div>
        </div>
      </ReflexElement>

    </ReflexContainer>

  );
};

