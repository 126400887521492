// @ts-nocheck
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "../../reusableComponents";
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "../../reusableComponents/Table";

import { Track } from "../AllApplicantsPage/AllApplicantsPage";
import { useNavigate, useParams } from "react-router-dom";
import DownloadCSV from "./Download";
import { omit } from "../BatchPage/OmitFunction";
import ApplBatchRow from "./ApplBatchRow";

interface ProgramProps {
  handle: string,
  id: string,
  logoUrl: string,
  name: string,
}

export interface BatchProps {
  applicant: {
    id: string;
    name: string;
    email: string;
    onelogin: string;
    track: Track;
    batch: string;
    program: ProgramProps;
    gender: string;
    location: string;
    streetNumber: string;
    addressSuffix: string;
    postcode: string;
    city: string;
    country: string;
    accountHolder: string;
    bankName: string;
    iban: string;
    bic: string;
    shirtSize: string;
    shirtStyle: string;
    foodIntolerances: string;
    scholarshipType: string;
  };
}

interface RouteParams {
  batch: string;
}

export default function BatchPage({ match }: any) {
  const token = useSelector((state: RootState) => state.auth.token);

  async function getParticipant(batch, token): Array {
    const promise = fetch(`/api/raw/participants/list/` + batch, {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + token }
    })

    return promise.then(r => r.json()).then(participants => {

      let formatApplicants = Array();
      for (var i = 0; i < participants.length; i++) {

        const details = participants[i].onboardingDetails;

        let applicant = {
          id: participants[i].id,
          name: participants[i].name,
          email: participants[i].email,
          onelogin: details.oneLogin.eduAddress,
          oneloginStatus: details.oneLogin.status,
          batch: participants[i].batch,
          track: participants[i].track.handle,
          gender: participants[i].gender,
          program: participants[i].program,
          location: details.location,
          streetNumber: details.address,
          addressSuffix: details.addressSuffix,
          postcode: details.postcode,
          city: details.city,
          country: details.country,
          accountHolder: details.accountHolder,
          bankName: details.bankName,
          iban: details.iban,
          bic: details.bic,
          shirtSize: details.shirtSize,
          shirtStyle: details.shirtStyle,
          foodIntolerances: details.foodIntolerances,
          scholarshipType: details.scholarshipType,
        };
        formatApplicants.push(applicant);
      };
      return formatApplicants;
    });
  }

  const tableColumns = [
    "Name",
    "Email",
    "Onelogin",
    "Status",
    "Batch",
    "Track",
    "Gender",
    "Program",
    "Location",
    "Street Number",
    "Postal Code",
    "City",
    "Country",
    "Scholarship",
    "Account Holder",
    "Bank Name",
    "iban",
    "bic",
    "Shirt",
    "Food Intolerances",
  ];

  const params = useParams();
  const [formatApplicants, updateFormatApplicants] = React.useState([]);

  React.useEffect(function effectFunction() {
    getParticipant(params.batch, token)
      .then(participants => {
        updateFormatApplicants(participants);
      });
  }, []);

  let downloadApplicants = formatApplicants.map((applicant) => {
    let downloadApplicant = omit(applicant, 'program')
    downloadApplicant["program"] = applicant.program.handle
    return downloadApplicant
  })

  return (
    <div className="applicant-page-wrapper">
      <br />
      <Grid container spacing="sm" alignItems="flex-end">
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <h1>Batch {params.batch}</h1>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <DownloadCSV data={downloadApplicants} filename={"Batch_Data_" + params.batch + ".csv"} />
        </Grid>
      </Grid>
      <br />
      <br />
      <div style={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableColumns.map((column, index) => (
                <TableCell key={index} align="left" component="th">
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {formatApplicants?.map(
              (applicant: ApplicantProps["applicant"], index: React.Key) => (
                <ApplBatchRow key={index} applicant={applicant} />
              )
            )}
          </TableBody>
        </Table>
        <br />
      </div>
    </div>
  );
}
