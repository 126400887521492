import { useState } from "react";
import { Button } from "../../reusableComponents";
import { Formik, Form } from "formik";
import { MentionsInput, Mention } from "react-mentions";
import { CreateComment, DeleteComment, EditComment } from "../../GraphQL/Mutations";
import { useMutation } from "@apollo/client";
import { LoadComments} from "../../GraphQL/Queries";

export default function CommentForm({ comment, setComment, text, setText, batchId, applicantId, }: any) {
  const [value, setValue] = useState({ body: "" });
  const [CreateMutation] = useMutation(CreateComment, {
    variables: {
      batch_id: parseInt(batchId),
      applicant_id: applicantId,
      comment_body: value.body
    },
    refetchQueries: [{
      query: LoadComments,
      variables: {
        batch_id: parseInt(batchId),
        applicant_id: applicantId
      }
    }]
  })

  const [EditMutation] = useMutation(EditComment, {
    variables: {
      batch_id: parseInt(batchId),
      applicant_id: applicantId,
      comment_body: value.body,
      comment_id: comment ? comment.id : null
    },
    refetchQueries: [{
      query: LoadComments,
      variables: {
        batch_id: parseInt(batchId),
        applicant_id: applicantId
      }
    }]
  })

  const [DeleteMutation] = useMutation(DeleteComment, {
    variables: {
      batch_id: parseInt(batchId),
      applicant_id: applicantId,
      comment_id: comment ? comment.id : '',
    },
    refetchQueries: [{
      query: LoadComments,
      variables: {
        batch_id: parseInt(batchId),
        applicant_id: applicantId
      }
    }]
  });
  const initialValues = { body: "" }

  function handleSubmit(value: any, { resetForm }: any) {
    if (comment) {
      EditMutation()
      setText("")
      setValue({ body: "" })
      setComment(null)
    }
    else {
      CreateMutation()
      setText("")
      setValue({ body: "" })
      setComment(null)
    }
    resetForm()
  }
  function handleDelete() {
    if (comment) {
      setText("")
      DeleteMutation()
      setComment(null)
    }
  }

  function closeEditForm() {
    setText("")
    setComment(null)
  }

  const defaultMentionStyle = {
    backgroundColor: "#cee4e5"
  };

  const users = [];

  const handleChange = (event: any, setFieldValue: any) => {
    var changedValue = event?.target.value;
    setText(changedValue)
    setValue({ body: changedValue });
    setFieldValue("value.body", value)
  };

  return (
    <div className="comment-form">
      <Formik
        initialValues={initialValues}
        validationSchema={null}
        enableReinitialize={true}
        onSubmit={async (value: any, resetForm) => {
          await handleSubmit(value, resetForm)
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <MentionsInput
              className="form-input-text"
              value={text ? text : value.body}
              style={{ margin: "auto", height: "150px", marginBottom: "30px" }}
              onChange={(e) => handleChange(e, setFieldValue)}
              name="body">
              <Mention
                markup="[__display__]{__id__}"
                trigger="@"
                data={users}
                style={defaultMentionStyle}
              />
            </MentionsInput>
            <Button
              label="Submit"
              size="small"
              type="submit"
            />
            {comment && (
              <>
                <br />
                <br />
                <Button
                  label="Close"
                  size="small"
                  onClick={() => closeEditForm()}
                />
                <Button
                  label="Delete"
                  size="small"
                  onClick={() => handleDelete()}
                />
              </>
            )}
          </Form>
        )}
      </Formik>{" "}
      <br />
    </div>
  );
}
