// @ts-nocheck
import { useSelector } from "react-redux";
import { Button, Grid } from "../../reusableComponents";
import { Formik, Form } from "formik";
import { initialValues, validationSchema } from "./formData";
import TextInput from "../../reusableComponents/FormInput/TextInput";
import SelectInput from "../../reusableComponents/FormInput/SimpleSelectInput";
import {
  genderData,
  scholarshipData,
} from "../AllApplicantsPage/filterData";
import { toast } from 'react-toastify';
import FileInput from "../../reusableComponents/FormInput/FileInput";


export default function AddApplicantForm() {
  const token = useSelector((state: RootState) => state.auth.token);
  const programs = useSelector((state: RootState) => state.programs);
  const tracks = useSelector((state: RootState) => state.tracks);
  const batches = useSelector((state: RootState) => state.batches);

  const postApplicant = (data) => {
    return fetch(`/api/raw/persons`, {
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + token },
      body: data
    }).then(async response => {
      response.status === 200 && toast.success("Success!");
    }).catch(error => {
      console.log(error)
      toast.error("Something went wrong, try again!");
    })
  }

  const addApplicant = (batchId, trackId, values) => {
    fetch(`/api/raw/trackInstances/find_by_track_&_batch/` + batchId + "/" + trackId, {
      method: 'GET'
    }).then(async response => {
      const trackInstance = await response.json()
      return trackInstance
    }).then(trackInstance => {
      let formData = new FormData();
      // To be refactored, but for now I want to see exactly what I am sending to the Java endpoint
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("email", values.email);
      formData.append("source", values.source);
      formData.append("gender", values.gender);
      formData.append("scholarship", values.scholarship);
      formData.append("consent", values.consent);
      formData.append("status", "NEW");
      formData.append("programId", values.program);
      formData.append("trackInstanceId", trackInstance.id);
      formData.append("cv", values.cv);
      { values.coverLetter && formData.append("coverLetter", values.coverLetter) }
      postApplicant(formData, token);
    }).catch(error => {
      console.log(error)
      toast.error("Something went wrong, try again!");
    })

  }

  return (
    <div className="applicant-page-wrapper ">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: any) => {
          addApplicant(values.batch, values.track, values)
        }}
      >
        {({ isSubmitting, dirty, isValid, setFieldValue }) => (
          <Form>
            <h1>Add applicant</h1>
            <br />
            <Grid container spacing="sm" alignItems="center">
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextInput label="First Name" name="firstName" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextInput label="Last Name" name="lastName" />
              </Grid> <br />
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextInput label="Email" name="email" />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4}>
                <SelectInput
                  name="scholarship"
                  label="Scholarship"
                  options={scholarshipData}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <SelectInput
                  name="program"
                  label="Program"
                  options={programs.map(program => {
                    var programIds = {
                      "key": program.id,
                      "label": program.name,
                      "value": program.id,
                    }
                    return programIds
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <SelectInput name="track" label="Track" options={tracks.map(track => {
                  var trackIds = {
                    "key": track.id,
                    "label": track.name,
                    "value": track.id,
                  }
                  return trackIds
                })} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <SelectInput name="batch" label="Batch" options={batches.map(batch => {
                  var batchIds = {
                    "key": batch.id,
                    "label": batch.name,
                    "value": batch.id,
                  }
                  return batchIds
                })} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <SelectInput
                  name="gender"
                  label="Gender"
                  options={genderData}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextInput label="Source" name="source" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FileInput
                  inputProps={{
                    id: 'cv',
                    name: 'cv',
                    onChange: e => setFieldValue('cv', e.target.files[0]),
                  }}
                  label="Cv"
                  onRemove={() => {
                    setFieldValue('cv', null)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FileInput
                  name="coverLetter"
                  label="Cover Letter"
                  onChange={(event) => {
                    setFieldValue("coverLetter", event.target.files[0]);
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ textAlign: "center" }}>
              <br />
              <Button
                label="Submit"
                size="small"
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
      <br />
    </div>
  );
}
