import React, { ReactNode } from "react";
import "./Grid.scss";
import cn from "classnames";

type Cols = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type Spacing = "sm" | "md" | "lg";
type JustifyContent = "flex-start" | "center" | "flex-end" | "space-between";
type AlignItems = "flex-start" | "center" | "flex-end";

export interface GridProps {
  children: ReactNode;
  container?: boolean;
  item?: boolean;
  xs?: Cols;
  sm?: Cols;
  md?: Cols;
  lg?: Cols;
  spacing?: Spacing;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
}

export const Grid: React.FC<GridProps> = ({
  children,
  container,
  item,
  xs,
  sm,
  md,
  lg,
  spacing,
  justifyContent,
  alignItems,
  ...props
}) => {
  const classNames = cn({
    "grid--container": container,
    "grid--item": item,
    [`grid--xs--${xs}`]: xs,
    [`grid--sm--${sm}`]: sm,
    [`grid--md--${md}`]: md,
    [`grid--lg--${lg}`]: lg,
    [`grid--spacing--${spacing}`]: spacing,
    [`grid--justifyContent--${justifyContent}`]: justifyContent,
    [`grid--alignItems--${alignItems}`]: alignItems,
  });

  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};
