import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { setContext } from "apollo-link-context";
import { useSelector } from "react-redux";
import { RootState } from ".";
import config from "./config";
import { createUploadLink } from "apollo-upload-client";

export const CreateClient = (): ApolloClient<NormalizedCacheObject> => {

        const token = useSelector((state: RootState) => state.auth.token)

        const authLink = setContext((_, { headers }) => {
                return {
                        headers: {
                                ...headers,
                                authorization: `Bearer ${token}`,
                        },
                };
        });
        const httpLink = createHttpLink({
                uri: config.backendUrl
        });
        const uploadLink = createUploadLink({
                uri: config.backendUrl
        });

        return new ApolloClient({
                cache: new InMemoryCache(),
                connectToDevTools: true,
                link: authLink.concat(uploadLink as any) as any
        });
};
