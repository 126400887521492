import { set_auth, clear_auth } from "./Constants";

const initialValues = {
        token: null,
};

export default function AuthReducer(
        state = initialValues,
        { type, payload }: any
) {
        switch (type) {
                case set_auth:
                        return {
                                ...state,
                                token: [payload],
                        };
                case clear_auth:
                        return {
                                undefined,
                        };
                default:
                        return state;
        }
}
