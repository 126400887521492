import { load_batches } from "./Constants";

const initialValues = null;

export default function BatchesReducer(
        state = initialValues,
        { type, payload }: any
) {  
        switch (type) {
                case load_batches:
                        return payload;
                default:
                        return state;
        }
}
