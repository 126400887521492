import TextAreaInput from "../../reusableComponents/FormInput/TextAreaInput";

export default function AcceptanceFormFood(props) {
  return (
    <div style={{ width: "100%" }} >
      {props.hideText ? null : (
        <p>
          To ensure a smooth preparation of our events we kindly ask you for sharing your food intolerances or any special diets with us.
        </p>)}
      <TextAreaInput label="Food Intolerances (optional)" name="onboardingDetails.foodIntolerances" rows={3} />
    </div>
  );
}
