import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../..";
import { TableCell, TableRow } from "../../reusableComponents/Table";
import { load_applicant_details } from "../Redux/Constants";
import { ApplicantProps } from "./AllApplicantsPage";

export default function ApplicantRow(props: ApplicantProps) {
  const {
    id,
    firstName,
    lastName,
    program,
    batch,
    trackInstance,
    coverLetter,
    status,
  } = props.applicant;

  let coverLetterBoolean = "😔";
  if (coverLetter === true) {
    coverLetterBoolean = "✅";
  }

  // To get the logo directly from the backend ( double work right now )
  let programLogo;
  if (program?.id === "adeade6a-dd0b-4e98-b05e-51b2e3a9e66d") {
    programLogo = <img src="https://i.ibb.co/jbm3rW7/Digital-Schmiede.png" alt="Program" style={{ maxHeight: "30px", zIndex: "-1" }} />
  } else if (program?.id === "adeade6a-dd0b-4e98-b05e-51b2e3a9e66f") {
    programLogo = <img src="https://i.ibb.co/XzFkLtH/Screenshot-2022-07-27-at-12-50-36.png" alt="Program" style={{ maxHeight: "30px", zIndex: "-1" }} />
  }

  const cells = [firstName + " " + lastName, programLogo, trackInstance?.batch?.name, trackInstance?.track?.handle, , coverLetterBoolean, status];
  const [hover, setHover] = useState(false);
  const applicantUrl = `/applicant/${trackInstance?.batch?.name}/${id}`;

  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);

  const details = (id) => {
    return fetch(`/api/raw/persons/` + id, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(async details => {
      const applicantDetails = await details.json()
      dispatch({
        type: load_applicant_details,
        payload: applicantDetails
      })
    }).catch(error => {
      console.log(error);
    });
  };


  return (
    <TableRow
      onMouseEnter={() => setHover(true)}
      onClick={() => { setHover(true); details(id) }}
      onMouseLeave={() => setHover(false)}
      hover={hover}
    >
      {cells.map((cell, index) => (
        <TableCell key={index} align="left" component="td">
          <Link to={applicantUrl} state={{ from: "allapplicantspage" }}>{cell}</Link>
        </TableCell>
      ))}
    </TableRow>
  );
}
