import React, { ReactNode } from "react";
import "./Table.scss";
import cn from "classnames";

export interface TableRowProps {
  children: ReactNode;
  hover?: boolean;
  selected?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick ?: () => void;
}

export const TableRow: React.FC<TableRowProps> = ({
  children,
  hover = false,
  selected = false,
  ...props
}) => {
  const classNames = cn("table-row", {
    "table-row--hover": hover,
    "table-row--selected": selected,
  });
  return (
    <tr className={classNames} {...props}>
      {children}
    </tr>
  );
};
