import * as Yup from "yup";


export const validationSchema = Yup.object({
  onboardingDetails: Yup.object({
    location: Yup.string().required("You must provide a location").nullable(),
    address: Yup.string().required("You must provide a street number").nullable(),
    postcode: Yup.string().required("You must provide a postcode").nullable(),
    city: Yup.string().required("You must provide a city").nullable(),
    country: Yup.string().required("You must provide a country").nullable(),
    accountHolder: Yup.string().required("You must provide an account holder").nullable(),
    bankName: Yup.string().required("You must provide a bank name").nullable(),
    iban: Yup.string().required("You must provide an iban").nullable(),
    bic: Yup.string().required("You must provide a bic").nullable(),
    shirtSize: Yup.string().required("You must provide a shirt size").nullable(),
    shirtStyle: Yup.string().required("You must provide a shirt style").nullable(),
    consentData: Yup.bool().required("You need to check this box").nullable(),
    consentAlumni: Yup.bool().required("You need to check this box").nullable(),
  })
});

export const shirtStyle = [
  {
    label: "Fitted cut, also known as women's apparel",
    value: "F",
  },
  {
    label: "Straight cut, often known as men's apparel",
    value: "M",
  },
];

export const shirtSize = [
  {
    label: "S",
    value: "S",
  },
  {
    label: "M",
    value: "M",
  },
  {
    label: "L",
    value: "L",
  },
  {
    label: "XL",
    value: "XL",
  },
  {
    label: "XXL",
    value: "XXL",
  },
];
