// Reusable function similar to https://lodash.com/docs#omit
// Remove a specific key from json object

export function omit(obj, omitKey) {
  return Object.keys(obj).reduce((result, key) => {
    if (key !== omitKey) {
      result[key] = obj[key];
    }
    return result;
  }, {});
}