export const create_form = "createForm"
export const select_batch = "selectBatch"
export const unselect_batch = "unselectBatch"
export const select_track = "selectTrack"
export const unselect_track = "unselectTrack"
export const select_status = "selectStatus"
export const unselect_status = "unselectStatus"
export const set_comment = "setComment"
export const set_auth = "setAuth"
export const clear_auth = "clearAuth"
export const clear_batch = "clearBatch"
export const clear_status = "clearStatus"
export const clear_track = "clearTrack"
export const filter_applicants = "filterApplicants"
export const load_batches = "loadBatches"
export const load_applicant_details = "loadApplicantDetails"
export const load_programs = "loadPrograms"
export const load_track_instances = "loadTrackInstances"
export const load_tracks = "loadTracks"