import React, { useState } from "react";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";
import { auth, googleProvider } from "../../firebase";
import { useNavigate } from "react-router-dom"
import { Image, Button } from "../../reusableComponents";
import DPSLogo from "../../Assets/dps-icon.svg";
import "./Login.scss";
import { useDispatch } from "react-redux";
import { setAuth } from "../Redux/Actions";
import { toast } from 'react-toastify';

export interface LoginProps { }

export interface stateType {
  from: string;
  permission: string;
}

export const Login: React.FC<LoginProps> = ({ }) => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
  let navigate = useNavigate();
  let from = "/all-applicants";
  const dispatch = useDispatch();

  const signInWithGoogle = (from: string) => {
    auth
      .signInWithPopup(googleProvider)
      .then(async (res) => {
        setCurrentUser(res.user);
        if (res.user) {
          const token = await res.user.getIdToken();
          dispatch(setAuth(token))
          navigate(from);
        }
      })
      .catch((error) => {
        console.log(error)
        toast.error("Something went wrong, try again!");
      });
  }


  return (
    <div className="login-wrapper">
      <Image alt="logo" src={DPSLogo} size="xsmall" />
      <h1 className="uppercase bold"> Recruitinator </h1>
      Please login with your unternehmerTUM account
      <div className="login-wrapper--button">
        <Button
          label="Login"
          size="small"
          onClick={() => signInWithGoogle(from)}
        ></Button>
      </div>
    </div>
  );
};
