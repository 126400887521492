import { create_form} from "./Constants";

export const initialValues = {
  onboardingDetails: {
    id: null,
    personId: "",
    location: "Munich",
    address: "",
    addressSuffix: "",
    postcode: "",
    city: "",
    country: "",
    accountHolder: "",
    bankName: "",
    iban: "",
    bic: "",
    food: "",
    shirtSize: "",
    shirtStyle: "",
    consentData: false,
    consentAlumni: false,
  }
};

export default function AcceptanceFormReducer(
  state = initialValues,
  { type, payload }: any
) {
  switch (type) {
    case create_form:
      return {
        onboardingDetails: {
        id: payload.id,
        personId: payload.personId,
        batch: payload.batch,
        name: payload.name,
        location: "Munich",
        address: payload.address,
        addressSuffix: payload.addressSuffix,
        postcode: payload.postcode,
        city: payload.city,
        country: payload.country,
        accountHolder: payload.accountHolder,
        bankName: payload.bankName,
        iban: payload.iban,
        bic: payload.bic,
        foodIntolerances: payload.foodIntolerances,
        shirtSize: payload.shirtSize,
        shirtStyle: payload.shirtStyle,
        consentData: payload.consentData,
        consentAlumni: payload.consentAlumni
      }
    };

    default:
      return state;
  }
}
