import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { configureStore } from "./store/configureStore";
import { PersistGate } from 'redux-persist/integration/react'
const { store, persistor } = configureStore()
export type RootState = ReturnType<any>;

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
}

render()