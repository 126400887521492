import "./FormInput.scss";
import { useField } from "formik";

export interface TextInputProps {
  name: string;
  label: string;
}

export default function TextInput({ label, ...props }: TextInputProps) {
  const [field, meta] = useField(props);
  const className =
    meta.touched && meta.error ? "form-input-text-error" : "form-input-text";

  return (
    <div className="input-field">
      <input {...field} {...props} type="text" className={className} />
      <label className="text-label">{label}</label>

      {meta.touched && meta.error ? (
        <p className="input-label-error">{meta.error}</p>
      ) : null}
    </div>
  );
}
