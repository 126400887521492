import React, { ReactNode } from "react";
import "./Table.scss";
import cn from "classnames";

export interface TableCellProps {
  children: ReactNode;
  component: keyof JSX.IntrinsicElements;
  align?: "center" | "left" | "right";
  size?: "sm" | "md";
}

export const TableCell: React.FC<TableCellProps> = ({
  children,
  component: Tag = "td",
  align = "left",
  size = "md",
  ...props
}) => {
  const classNames = cn("table-cell", {
    [`table-cell--align--${align}`]: align,
    [`table-cell--size--${size}`]: size,
  });
  return (
    <Tag {...props}>
      <div className={classNames} {...props}>
        {children}
      </div>
    </Tag>
  );
};
