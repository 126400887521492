import "./ApplicantPage.scss";

export default function DocumentFrame({ data }: any) {

  return (
    <div className="applicant-document-wrapper">
      <iframe src={data} width="100%" height="1000" />
    </div>
  );
}
