type Config = {
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  }
  backendUrl: string;
}

const configBase = {
  firebaseConfig: {
    apiKey: 'AIzaSyB4LM0aCPAXvGBOrgmgWOchxS2QsiXunSg',
    authDomain: 'dps-website-staging-0.firebaseapp.com',
    databaseURL: 'https://dps-website-staging-0.firebaseio.com',
    projectId: 'dps-website-staging-0',
    storageBucket: 'dps-website-staging-0.appspot.com',
    messagingSenderId: '950996531347',
    appId: '1:950996531347:web:a838fccc304803db80f566'
  }
}

type EnvType = 'staging' | 'production' | 'development'

const configs: Record<EnvType, Config> = {
  // use python backend running locally but the staging env's auth and firestore stuff - for the time being
  development: {
    ...configBase,
    backendUrl: '/papi/graphql'
  },

  // deployed staging environment
  staging: {
    ...configBase,
    backendUrl: 'https://rt-backend-staging-w2a4py2tca-ey.a.run.app/graphql'
  },

  // deployed production environment
  production: {
    firebaseConfig: {
      apiKey: 'AIzaSyDLCePgRPpr3eMy_bExSJxdLXqZkmGdxJA',
      authDomain: 'dps-website-244212.firebaseapp.com',
      databaseURL: 'https://dps-website-244212.firebaseio.com',
      projectId: 'dps-website-244212',
      storageBucket: 'dps-website-244212.appspot.com',
      messagingSenderId: '836682187511',
      appId: '1:836682187511:web:37d705d934ff1b54',
    },
    backendUrl: 'https://rt-backend-production-w2a4py2tca-ey.a.run.app/graphql',
  }
}

// get the current env - defaults to "development" when that's not set
const env: EnvType = (process.env.REACT_APP_ENV) as 'staging' | 'production' | undefined ?? 'development'

// provide the config object for the application
export default configs[env]
