import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TableCell, TableRow } from "../../reusableComponents/Table";
import DateFormat from "../DateFormat";

export default function BatchRow(props: any) {
        const { name, startDate, endDate } = props.batch;

        const cells = [name, startDate, endDate];
        const [hover, setHover] = useState(false);

        const batchUrl = `/batch/${name}`;

        return (
                <TableRow
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        hover={hover}
                >
                        {cells.map((cell, index) => (
                                <TableCell
                                        key={index}
                                        align="left"
                                        component="td"
                                >
                                        {index === 0 ? (
                                                <Link to={batchUrl}>{cell}</Link>
                                        ) : (
                                                <Link to={batchUrl}>
                                                        <DateFormat
                                                                date={cell}
                                                        />
                                                </Link>
                                        )}
                                </TableCell>
                        ))}
                </TableRow>
        );
}
