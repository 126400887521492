import { Grid } from "../../reusableComponents";
import TextInput from "../../reusableComponents/FormInput/TextInput";

export default function AcceptanceFormBank(props) {
  return (
    <div style={{ width: "100%" }} >
      {props.hideText ? null : (
        <p>
          Please share your bank account details with us as we will need it for the transfer of the monthly scholarship. 
          Bank accounts from the European Union / Schengen Area are highly recommended due to faster and cheaper money transfer.
        </p>)}
      <Grid container spacing="sm" alignItems="center">
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextInput label="Account Holder" name="onboardingDetails.accountHolder" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextInput label="Name of Bank" name="onboardingDetails.bankName" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextInput label="Account number / IBAN" name="onboardingDetails.iban" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextInput label="SWIFT code / BIC" name="onboardingDetails.bic" />
        </Grid>
      </Grid>
    </div>
  );
}
