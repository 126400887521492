import React, { useState } from "react";

import { Grid } from "../Grid/Grid";
import { Done } from "../Icons";
import "./FormInput.scss";
import { useDispatch } from "react-redux";

export interface SelectInputProps {
  title: string;
  items: {
    label: string;
    key: number;
    value: any;
  }[];
  selection?: any;
  selectAction: (arg0: any) => void;
  unSelectAction: (arg0: any) => void;
  clearAction: () => void;
  multiSelect?: boolean;
  onClick?: (arg0: any) => void;
  error?: string;
}
export default function SelectInput(props: SelectInputProps) {
  const [open, setOpen] = useState(false);
  const selection = props.selection
  const toggle = () => setOpen(!open);

  const dispatch = useDispatch();

  function handleOnClick(item: { label?: string; key: any, value: any }) {
    if (!selection.some((current: { key: any }) => current === item.value)) {

      if (!props.multiSelect) {
        dispatch(props.selectAction(item.value))
      } else if (props.multiSelect) {
        dispatch(props.selectAction(item.value))
      }
    } else {
      dispatch(props.unSelectAction(item.value))
    }
  }

  function selectAllItems(items) {
    items.forEach(item => {
      dispatch(props.selectAction(item.value))
    });
  }

  function clearAllItems() {
    dispatch(props.clearAction());
  };

  function isItemInSelection(item: { label?: string; key: any, value: any }) {
    if (selection.some((current: { key: any }) => current === item.value)) {
      return true;
    }
    return false;
  }

  return (
    <div className="form-input-select-wrapper">
      <div
        tabIndex={0}
        className="form-input-select-header"
        role="button"
        onKeyPress={() => toggle()}
        onClick={() => toggle()}
      >
        <div className="form-input-select-header-title">
          <span>{props.title}</span>
        </div>
      </div>
      <br />

      <ul className={`form-input-select-list-${open}`}>
        <Grid container spacing="sm" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <li className="form-input-select-list-item" onClick={() => selectAllItems(props.items)}> <button>Show All</button></li>
          </Grid>
        </Grid>
        {props.items.map((item) => (
          <li className="form-input-select-list-item" key={item.key}>
            <button type="button" onClick={() => handleOnClick(item)}>
              <span className={isItemInSelection(item) ? "bold": ""}>{item.label}</span>
            </button>
          </li>
        ))}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <li className="form-input-select-list-item" onClick={() => clearAllItems()}><button>Clear All</button></li>
        </Grid>
      </ul>
      {props.error && <p className="input-label-error">{props.error}</p>}
    </div>
  );
}
