import { gql } from "@apollo/client";


export const LoadComments = gql`
  query ($batch_id: Int!, $applicant_id: String!) {
    applicantComments(batch_id: $batch_id, applicant_id: $applicant_id) {
      ... on CommentList {
        list {
          id
          body
          createdAt
          updatedAt
          user {
            name
            photo
            email
            uid
          }
        }
      }
      ... on Exception {
        message
      }
    }
  }
`;

export const LoadApplicant = gql`
  query ($batch_id: Int!, $applicant_id: String!) {
    applicantDetails(batch_id: $batch_id, applicant_id: $applicant_id) {
      ... on Applicant {
        name
        email
        batch
        track {
          handle
          name
        }
        program
        scholarship
        gender
        cv 
        coverLetter 
        status
        project
        strengths
        source
        acceptanceFormData {
          location
          streetNumber
          addressSuffix
          postcode
          city
          country
          accountHolder
          bankName
          iban
          bic
          shirtSize
          shirtStyle
          foodIntolerances
        }
      }
      ... on Exception {
        message
      }
    }
  }
`;


export const LoadUser = gql`
  query {
    user {
      ... on User {
        uid
        name
        email
        photo
      }
      ... on Exception {
        message
      }
    }
  }
`;