import React, { ReactNode } from "react";

export interface TableHeadProps {
  children: ReactNode;
  className?: string;
}

export const TableHead: React.FC<TableHeadProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <thead className={className} {...props}>
      {children}
    </thead>
  );
};
