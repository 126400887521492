import React, { useEffect } from "react";
import { Grid } from "../../reusableComponents";
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "../../reusableComponents/Table";
import ApplicantRow from "./ApplicantRow";
import FormInputSelect from "../../reusableComponents/FormInput/SelectInput";
import { statusData } from "./filterData";
import { FaPlus } from 'react-icons/fa';
import {
  selectTrack,
  unselectTrack,
  selectBatch,
  unselectBatch,
  selectStatus,
  unselectStatus,
  clearBatch,
  clearStatus,
  clearTrack,
} from "../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { filter_applicants, load_batches, load_programs, load_track_instances, load_tracks } from "../Redux/Constants";
import { BatchProps } from "../AllBatchesPage/AllBatchesPage";
import { useNavigate } from "react-router-dom";
import DownloadCSV from "../BatchPage/Download";

// You can refactor them in a better way, separarting instances on their own

export interface TrackInstanceProps {
  id: string;
  startDate: string;
  endDate: string;
  qnaUrl: string;
  challenges: [string];
  track: TrackProps;
  batch: BatchProps;
}
export interface ProgramProps {
  id: string;
  handle: string;
  name: string;
  logoUrl: string;
}

export interface TrackProps {
  id: string;
  handle: string;
  name: string;
  operatorIds: [string];
}

export interface ApplicantProps {
  applicant: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    program: {
      id: string;
    }
    batch: string;
    trackInstance: {
      batch: {
        name: string;
      }
      track: {
        handle: string;
      }
    };
    coverLetter: any;
    status: string;
  };
}

export default function AllApplicantsPage() {
  const batches = useSelector((state: RootState) => state.batches);
  const batch = useSelector((state: RootState) => state.filterApplicants.batch);
  const track = useSelector((state: RootState) => state.filterApplicants.track);
  const updated = useSelector((state: RootState) => state.filterApplicants.updated);
  const program = useSelector((state: RootState) => state.programs);
  const trackInstances = useSelector((state: RootState) => state.trackInstances);
  const tracks = useSelector((state: RootState) => state.tracks);

  const status = useSelector(
    (state: RootState) => state.filterApplicants.status
  );

  const tableColumns = [
    "Name",
    "Program",
    "Batch",
    "Track",
    "Cover Letter",
    "Status",
  ];

  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.auth.token);
  const data = useSelector((state: RootState) => state.applicants.allApplicants);

  const filter = (batchIds, status, tracks, token) => {
    const data = {
      "batchIds": batchIds,
      "status": status,
      "tracks": tracks
    }

    return fetch(`/api/raw/persons/_filter`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(async applicants => {
      const results = await applicants.json()
      dispatch({
        type: filter_applicants,
        payload: results
      })
    })
      .catch(error => {
        console.log(error);
      });
  };

  const loadPrograms = () => {
    return fetch(`/api/raw/programs`, {
      method: 'GET',
    }).then(async programs => {
      const results = await programs.json()
      dispatch({
        type: load_programs,
        payload: results
      })
    })
      .catch(error => {
        console.log(error);
      });
  }

  const loadTracks = () => {
    return fetch(`/api/raw/tracks`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-type': 'application/json'
      },
    }).then(async programs => {
      const details = await programs.json()
      dispatch({
        type: load_tracks,
        payload: details
      })
    })
      .catch(error => {
        console.log(error);
      });
  }

  const loadTrackInstances = () => {
    return fetch(`/api/raw/trackInstances`, {
      method: 'GET',
    }).then(async trackInstances => {
      const details = await trackInstances.json()
      dispatch({
        type: load_track_instances,
        payload: details
      })
    })
      .catch(error => {
        console.log(error);
      });
  }
  const loadBatches = () => {
    return fetch(`/api/raw/batches`, {
      method: 'GET'
    }).then(async batches => {
      const details = await batches.json()
      dispatch({
        type: load_batches,
        payload: details
      })
    })
      .catch(error => {
        console.log(error);
      });
  };

  let navigate = useNavigate();

  useEffect(() => {
    // The check makes sure there is not going to be called every single time
    if (data === null || updated === true) {
      filter(batch, status, track, token);
    }
    if (program === null) {
      loadPrograms();
    }
    if (trackInstances === null) {
      loadTrackInstances();
    }
    if (tracks === null) {
      loadTracks();
    }
    if (batches === null) {
      loadBatches();
    }
  }, [batch, status, track]);


  let downloadApplicants = data?.map((applicant) => {
    let downloadApplicant = [];
    downloadApplicant["batch"] = applicant.trackInstance.batch.name
    downloadApplicant["gender"] = applicant.gender
    downloadApplicant["name"] = applicant.firstName + applicant.lastName
    downloadApplicant["source"] = applicant.source
    downloadApplicant["status"] = applicant.status
    downloadApplicant["track"] = applicant.trackInstance.track.handle
    downloadApplicant["program"] = applicant.program.handle
    return downloadApplicant
  }
  )

  return (
    <div className="applicant-page-wrapper">
      <Grid container spacing="sm" alignItems="flex-end">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h1>Applicants</h1>
          <span>
            Found {data?.length} matches
          </span>
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={1}>
          <br />
          {batches ? <FormInputSelect
            title="Batch"
            items={batches?.map(batch => {
              var batchNumbers = {
                "key": batch.id,
                "label": batch.name,
                "value": parseInt(batch.name),
              }
              return batchNumbers
            })}
            multiSelect
            selectAction={selectBatch}
            unSelectAction={unselectBatch}
            clearAction={clearBatch}
            selection={batch}
          /> : null}
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={1}>
          {tracks ? <FormInputSelect
            title="Track"
            items={tracks?.map(track => {
              var trackHandles = {
                "key": track.id,
                "label": track.handle,
                "value": track.handle,
              }
              return trackHandles
            })}
            multiSelect
            selectAction={selectTrack}
            unSelectAction={unselectTrack}
            clearAction={clearTrack}
            selection={track}
          /> : null}
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={1}>
          <FormInputSelect
            title="Status"
            items={statusData}
            multiSelect
            selectAction={selectStatus}
            unSelectAction={unselectStatus}
            clearAction={clearStatus}
            selection={status}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={6} lg={4}>
          <div></div>
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={1}>
          <div className="new-button" onClick={() => navigate("/add-applicant/")}>
            Add Applicant
            <button><FaPlus /></button>
          </div>
        </Grid> <Grid item xs={12} sm={2} md={2} lg={1}>
          <DownloadCSV data={downloadApplicants} filename={"DPS-Data.csv"} />
        </Grid>
      </Grid>
      <br />
      <br />
      <div>
        <Table>
          <TableHead>
            <TableRow>
              {tableColumns.map((column, index) => (
                <TableCell key={index} align="left" component="th">
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(
              (applicant: ApplicantProps["applicant"], index: React.Key) => (
                <ApplicantRow key={index} applicant={applicant} />
              )
            )}
          </TableBody>
        </Table>
      </div>
    </div >
  );
}
