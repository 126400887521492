import { gql } from "@apollo/client";

export const SendEmail = gql`
  mutation(
    $applicant_id: String!
    $email_type: String!
    $batch_id: Int!
  ) {
    sendEmail(
      applicant_id: $applicant_id
      email_type: $email_type
      batch_id: $batch_id
    ) {
      ... on Status {
        code
      }
      ... on Exception {
        message
      }
    }
  }
`;


export const UpdateStatus = gql`
  mutation(
    $applicant_id: String!
    $batch_id: Int!
    $status: String!
  ) {
    updateStatus(
      applicant_id: $applicant_id
      batch_id: $batch_id
      status: $status
    ) {
      ... on Status {
        code
      }
      ... on Exception {
        message
      }
    }
  }
`;

export const SendEmailDocuments = gql`
  mutation(
    $applicant_name: String!
    $applicant_email: String!
    $track: String!
    $batch_id: Int!
  ) {
    sendEmailDocuments(
      applicant_name: $applicant_name
      applicant_email: $applicant_email
      track: $track
      batch_id: $batch_id
    ) {
      ... on Status {
        code
      }
      ... on Exception {
        message
      }
    }
  }
`;

export const SaveForm = gql`
mutation(
    $batch_id: Int!
    $applicant_id: String!
    $name: String!
    $email: String!
    $program: String!
    $track: String!
    $location: String!
    $streetNumber: String!
    $addressSuffix: String!
    $postcode: String!
    $city: String!
    $country: String!
    $accountHolder: String!
    $bankName: String!
    $iban: String!
    $bic: String!
    $shirtSize: String!
    $shirtStyle: String!
    $foodIntolerances: String!
  ) {
    saveForm(batch_id: $batch_id, applicant_id: $applicant_id,name: $name, email: $email, program: $program, track: $track, location: $location, streetNumber: $streetNumber, addressSuffix: $addressSuffix, postcode: $postcode, city: $city, country: $country, accountHolder: $accountHolder, bankName: $bankName, iban: $iban, bic: $bic, shirtSize:$shirtSize, shirtStyle: $shirtStyle, foodIntolerances: $foodIntolerances) {
     ...on Status {
        code
        message
      }
    ...on Exception{
        message
      }
    }
}`;

export const CreateComment = gql`
mutation(
    $batch_id: Int!
    $applicant_id: String!
    $comment_body: String!
  ) {
  createComment( batch_id: $batch_id, applicant_id:$applicant_id, comment_body:$comment_body) {
     ... on Status {
      code
      message
    }
    ... on Exception{
        message
    }
}
}
`

export const DeleteComment = gql`
mutation(
    $batch_id: Int!
    $applicant_id: String!
    $comment_id: String!
  ) {
    deleteComment( batch_id: $batch_id, applicant_id:$applicant_id,comment_id:$comment_id) {
     ... on Status {
      code
      message
    }
    ... on Exception{
        message
    }
}
}
`

export const EditComment = gql`
  mutation(
    $batch_id: Int!
    $applicant_id: String!
    $comment_body: String!
    $comment_id: String!
  ) {
    editComment(batch_id:$batch_id, applicant_id:$applicant_id, comment_body:$comment_body, comment_id:$comment_id) {
     ...on Status {
        code
        message
      }
    ...on Exception{
        message
      }
    }
}
`


export const EditApplicant = gql`
mutation($applicant_id: String!, $batch_id: Int!, $updated_data: JSON!) {
   editApplicant(applicant_id: $applicant_id, batch_id:$batch_id, updated_data:$updated_data){
    ...on Status {
        code
        message
      }
    ...on Exception{
        message
      }
    }
}
`