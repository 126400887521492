import { formatDistance } from 'date-fns'

const DateDistancePast = ({ date }: any) => {
        const dateObject = new Date(date);
        var formatedDate = formatDistance(dateObject, new Date(), { addSuffix: true })
        return <h6 className="left time">
                {formatedDate}
        </h6>
}

export default DateDistancePast