import React from "react";
import { Danger, Done, Warning } from "../Icons";
import { Grid } from "../Grid/Grid";
import "./Alert.scss";

export interface AlertProps {
  severity: string;
}

const IconMapping: { [key: string]: JSX.Element } = {
  error: <Danger fill="var(--alert-error)" />,
  success: <Done fill="var(--alert-success)" />,
  warning: <Warning fill="var(--alert-warning)" />,
  info: <Danger fill="var(--alert-info)" />,
};

export const Alert: React.FC<AlertProps> = ({
  severity,
  children,
  ...props
}) => {
  return (
    <div className="alert">
      <div className={["alert--wrapper", `alert--${severity}`].join(" ")}>
        <Grid container spacing="sm" alignItems="center">
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <div> {IconMapping[`${severity}`]}</div>
          </Grid>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <div className="alert--title bold"> {severity}</div>
            <div className="alert--message"> {children}</div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
