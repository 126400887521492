import { load_programs } from "./Constants";

const initialValues = null;

export default function ProgramsReducer(
        state = initialValues,
        { type, payload }: any
) {  
        switch (type) {
                case load_programs:
                        return payload;
                default:
                        return state;
        }
}
