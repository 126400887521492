import { filter_applicants, load_applicant_details } from "./Constants";

const initialValues = {
        allApplicants: null, 
        selectedApplicant: null
};

export default function AllApplicantsReducer(
        state = initialValues,
        { type, payload }: any
) {
        switch (type) {
                case filter_applicants:
                        return {
                                ...state, 
                                allApplicants: payload
                        }
                case load_applicant_details: 
                        return {
                                ...state,
                                selectedApplicant: payload
                        }
                default:
                        return state;
        }
}
