import "./FormInput.scss";
import { useField } from "formik";

import { FaFileUpload } from 'react-icons/fa';

export interface FileInputProps {
        inputProps: {
            name: string;  
            onChange?: any;    
        }
        label: string;
        
}

export default function FileInput({ label, ...props }: FileInputProps) {

        return (
                <div className="input-field">
                        <input {...props.inputProps} type="file" className="form-input-file"/>
                        <label className="text-label">{label}</label>
                </div>
        );
}
