import React from "react";
import { IconsProps } from "./Icons.stories";

export const Menu: React.FC<IconsProps> = ({ fill = "var(--black)" }) => {
        return (
                <svg width="24"
                        height="24" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
        );
};
