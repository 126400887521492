import Header from "./Header";

export default function ThankYou() {
        return (
                <div className="thank-you-content">
                        <Header />
                        <div><br /><br /><br />
                                <h1> Thank You !</h1>
                                <br />
                                <p>
                                        We have received the submitted form.
                                        <br />
                                        You are going to receive by email some important documents.
                                        <br />
                                </p>
                        </div>
                        <div className="thank-you-footer">
                                <a
                                        href="https://digitalproductschool.io/legal-disclosure"
                                        className="u-link"
                                >

                                        <span>Legal Notice</span>
                                </a>
                                <a
                                        href="https://digitalproductschool.io/privacy-policy/utum/"
                                        className="u-link"
                                >
                                        <span>Privacy Policy</span>
                                </a>
                                <a href="https://digitalproductschool.io/"
                                        className="u-link">
                                        <span>DPS Website</span>
                                </a>
                        </div>
                </div>
        );
}
