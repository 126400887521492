import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
        key: 'root',
        storage,
}

const middlewares = [thunk];

const persistedReducer = persistReducer(persistConfig, rootReducer)

export function configureStore() {
        let store = createStore(persistedReducer, composeWithDevTools(
                applyMiddleware(...middlewares),
                // other store enhancers if any
        ))
        let persistor = persistStore(store)
        return { store, persistor }
}