import { Grid } from "../../reusableComponents";
import TextInput from "../../reusableComponents/FormInput/TextInput";
import SimpleSelectInput from "../../reusableComponents/FormInput/SimpleSelectInput";
import { countries } from "./Countries";

type AcceptanceFormAddressProps = {
  hideText?:boolean, 
  batch?:string,
}

export default function AcceptanceFormAddress(props:AcceptanceFormAddressProps) {
  return (
    <div style={{ width: "100%" }} >
      {props.hideText ? null : (
        <p>
          Congratulations and welcome to Digital Product School 🎉.
          <br />
          <br />
          We will send you the official documents as soon as have accepted our offer by submitting required information and by agreeing to the processing of your personal data below.
          <br />
          <br />
          Please note that this form asks you for your <b>postal address in Munich or its environs</b> during the three months at DPS. 
          If you are still looking for accommodation in Munich, you can also submit your current address and share the new one with us as soon as you have found a stay in Munich or its environs, otherwise you might be not eligible for the scholarship.
        </p>
      )}
      <br />
      <Grid container spacing="sm" alignItems="center" >
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextInput label="Street &amp; number" name="onboardingDetails.address" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextInput label="Address suffix (optional)" name="onboardingDetails.addressSuffix" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextInput label="Postcode" name="onboardingDetails.postcode" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextInput label="City" name="onboardingDetails.city" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <SimpleSelectInput label="Country" name="onboardingDetails.country" options={countries} />
        </Grid>
      </Grid>
    </div>
  );
}
