
import { Table, TableBody, TableCell, TableRow } from "../../reusableComponents/Table";
import { Grid } from "../../reusableComponents";
import { useMutation } from "@apollo/client";
import { SendEmail, UpdateStatus } from "../../GraphQL/Mutations";
import { useNavigate } from "react-router-dom";
import { RootState } from "../..";
import "./ApplicantPage.scss";
import { useState } from "react";
import { ProgramProps, TrackInstanceProps } from "../AllApplicantsPage/AllApplicantsPage";
import { FaEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import { load_applicant_details } from "../Redux/Constants";
import { toast } from "react-toastify";

interface ApplicantInfoProps {
  data: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    batch: string;
    program: ProgramProps;
    trackInstance: TrackInstanceProps;
    status: string;
    gender: string;
    project: string;
    strengths: string;
    source: string;
    scholarship: boolean;
    createdAt: string; // timestamp
    onboardingDetailsId: string;
    onboardingDetails: {
      location: string;
      addressSuffix: string,
      address: string,
      postcode: string,
      city: string,
      country: string,
      accountHolder: string,
      bankName: string,
      iban: string,
      bic: string,
      shirtStyle: string,
      shirtSize: string,
      foodIntolerances: string,
      scholarshipType: string,
    }
  };
  batchId: string;
  applicantId: string;
}

export default function ApplicantInfo({
  data,
  batchId,
  applicantId,
}: ApplicantInfoProps) {
  const { id, firstName, lastName, email, program, trackInstance, status, project, strengths, source, gender, onboardingDetails } = data;
  const { track, batch } = trackInstance
  const { handle: trackHandle } = track
  const { name: batchName } = batch
  const { handle: programHandle } = program
  const token = useSelector((state: RootState) => state.auth.token);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [emailType, setEmailType] = useState("")
  const [statusType, setStatusType] = useState("")
  const [updateStatus] = useMutation(UpdateStatus, {
    variables: {
      applicant_id: applicantId,
      batch_id: parseInt(batchId),
      status: statusType
    }
  });

  const acceptPerson = (id) => {
    return fetch(`/api/raw/persons/` + id + "/accept", {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(() => {
      toast.success("Success!");
    }).catch(error => {
      console.log(error);
      toast.success("Error!");
    });
  };

  const sendAgreement = (id) => {
    return fetch(`/api/raw/participants/` + id + "/documents/send", {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(() => {
      toast.success("Success!");
    }).catch(error => {
      console.log(error);
      toast.success("Error! " + error);
    });
  };

  const [sendEmail] = useMutation(SendEmail, {
    variables: {
      email_type: emailType,
      applicant_id: applicantId,
      batch_id: parseInt(batchId),
    }
  });

  const defineEmail = async (email: string, status: string) => {
    data.status = status
    await setEmailType(email)
    sendEmail()
    dispatch({
      type: load_applicant_details,
      payload: data
    })
    toast.success("Success!");
  }

  const defineStatus = async (status: string) => {
    data.status = status
    await setStatusType(status)
    updateStatus()
    dispatch({
      type: load_applicant_details,
      payload: data
    })
    toast.success("Success!");
  }

  return (
    <div className="applicant-info-wrapper">
      <h1 className="center">{firstName + " " + lastName + "  "}
        <button
          onClick={() => navigate("/edit-applicant/" + batchId + "/" + applicantId)}
        ><FaEdit size="2em" /></button></h1>
      <br />
      <Grid container spacing="sm" alignItems="flex-start">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th">Email:</TableCell>
                <TableCell component="td"><a href={`mailto:${email}`}>{email}</a></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Program:</TableCell>
                <TableCell component="td">{programHandle}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th">Track:</TableCell>
                <TableCell component="td">{trackHandle}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Batch:</TableCell>
                <TableCell component="td">{batchName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Status:</TableCell>
                <TableCell component="td">{status}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Source:</TableCell>
                <TableCell component="td">{source}</TableCell>
              </TableRow>
              {project && strengths && <>
                <TableRow>
                  <TableCell component="th">Project:</TableCell>
                  <TableCell component="td">{project}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Strengths:</TableCell>
                  <TableCell component="td">{strengths}</TableCell>
                </TableRow></>
              }
              {onboardingDetails && <>
                <TableRow>
                  <TableCell component="th">Location:</TableCell>
                  <TableCell component="td">{onboardingDetails.location}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Street &amp; Nr:</TableCell>
                  <TableCell component="td">{onboardingDetails.address}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Suffix:</TableCell>
                  <TableCell component="td">{onboardingDetails.addressSuffix}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Postcode:</TableCell>
                  <TableCell component="td">{onboardingDetails.postcode}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">City:</TableCell>
                  <TableCell component="td">{onboardingDetails.city}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Country:</TableCell>
                  <TableCell component="td">{onboardingDetails.country}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Bank:</TableCell>
                  <TableCell component="td">{onboardingDetails.bankName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Account Holder:</TableCell>
                  <TableCell component="td">{onboardingDetails.accountHolder}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">IBAN:</TableCell>
                  <TableCell component="td">{onboardingDetails.iban}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">BIC:</TableCell>
                  <TableCell component="td">{onboardingDetails.bic}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Intolerances:</TableCell>
                  <TableCell component="td">{onboardingDetails.foodIntolerances}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Shirt Size &amp; Style:</TableCell>
                  <TableCell component="td">{onboardingDetails.shirtSize} &amp; {onboardingDetails.shirtStyle}</TableCell>
                </TableRow> </>}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <br />

      <Grid container spacing="sm" alignItems="flex-start">
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <button className="new-button"
            onClick={() => defineEmail("sendRejection", "REJECTED")}
          >Reject</button>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1}>
          <button className="new-button"
            onClick={() => defineEmail("sendPromising", "PROMISING")}
          >Promising</button>
        </Grid>


        <Grid item xs={1} sm={1} md={1} lg={1}>
          <button className="new-button"
            onClick={() => defineEmail("sendInvitation", "INVITED TO INTERVIEW")}
          >Invite to Interview</button>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1}>
          <button className="new-button"
            onClick={() => defineStatus("INTERVIEW_SCHEDULED")}
          >Interview Scheduled</button>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1}>
          <button className="new-button"
            onClick={() => defineStatus("INTERVIEW_DONE")}
          >Interview Done</button>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1}>
          <button className="new-button"
            onClick={() => acceptPerson(id)}
          >Accept</button>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1}>
          <button className="new-button"
            onClick={() => defineEmail("sendWaitingList", "WAITING LIST")}
          >Waiting List</button>
        </Grid>
      </Grid>


      <br />
      More options:
      <br />  <br />
      <Grid container spacing="sm" alignItems="flex-start">

        <Grid item xs={1} sm={1} md={1} lg={1}>
          <button className="new-button"
            onClick={() => defineStatus("DUPLICATED")}
          >Duplicated </button>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <button className="new-button"
            onClick={() => defineStatus("WITHDRAWN")}
          >Withdrawn</button>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <button className="new-button"
            onClick={() => sendAgreement(id)}
          >Agreements</button>
        </Grid>
      </Grid>
      <br /><br />
    </div >
  );
}

