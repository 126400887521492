// @ts-nocheck


import * as Yup from "yup";

export const initialValues = {
  firstName: null,
  lastName: null,
  email: null,
  program: null,
  track: null,
  scholarship: null,
  consent: true,
  gender: null,
  cv: null,
  coverLetter: null,
  source: null
};

export function checkIfFilesAreTooBig(files?: [File]): boolean {
  let valid = true;
  if (files) {
    files.map((file) => {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        valid = false;
      }
    });
  }
  return valid;
}

export function checkIfFilesAreCorrectType(files?: [File]): boolean {
  let valid = true;
  if (files) {
    files.map((file) => {
      if (!["application/pdf"].includes(file.type)) {
        valid = false;
      }
    });
  }
  return valid;
}

export const validationSchema = Yup.object({
  firstName: Yup.string().required("You must provide a first name").nullable(),
  lastName: Yup.string().required("You must provide a last name").nullable(),
  email: Yup.string().required("You must provide an email").nullable(),
  program: Yup.string().required("You must provide a program").nullable(),
  track: Yup.string().required("You must provide a track").nullable(),
  cv: Yup.mixed().required("You must provide a cv").nullable(),
  coverLetter: Yup.mixed().nullable(),

});