import { useQuery } from "@apollo/client";
import { LoadComments } from "../../GraphQL/Queries";
import { Alert, CircularLoading, Grid, Image } from "../../reusableComponents";
import CommentForm from "./CommentForm";
import { Edit } from "../../reusableComponents/Icons/Edit";
import DateDistancePast from "../DateDistancePast";
import { useState } from "react";
import { auth } from "../../firebase";

export default function CommentList({ batchId, applicantId }: any) {
  const [text, setText] = useState(null);
  const [comment, setComment] = useState(null);
  const { loading, error, data } = useQuery(LoadComments, {
    variables: {
      batch_id: parseInt(batchId),
      applicant_id: applicantId,
    },
  });
  if (loading) return <CircularLoading />;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  function submit(comment: any) {
    setText(comment.body);
    setComment(comment);
  }
  return (
    <>
      <br />
      <h3>Comments</h3>
      <hr className="solid"></hr>
      {data.applicantComments.list.map((comment: any) => (
        <>
          <Grid container spacing="sm" alignItems="center">
            <Grid item xs={2} sm={2} md={3} lg={3}>
              <Image
                alt="user"
                src={comment.user.photo}
                round={true}
                align="center"
                size="small"
              />
            </Grid>

            <Grid item xs={9} sm={8} md={8} lg={8}>
              <h6 className="left">{comment.user.name}</h6>
              {comment.createdAt && (
                <DateDistancePast date={comment.createdAt} />
              )}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              {comment.user.uid === auth.currentUser?.uid && (
                <div>
                  <div onClick={() => submit(comment)}>
                    <Edit fill="none" />
                  </div>
                </div>
              )}
            </Grid>

            <Grid item xs={12} sm={8} md={12} lg={12}>
              {comment.body}
            </Grid>
          </Grid>
          <br />
          <br />
        </>
      ))}
      <CommentForm
        text={text}
        setText={setText}
        comment={comment}
        setComment={setComment}
        batchId={batchId}
        applicantId={applicantId}
      />
    </>
  );
}
