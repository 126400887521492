import React from "react";
import { Grid } from "../../reusableComponents";
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "../../reusableComponents/Table";
import BatchRow from "./BatchRow";
import { useSelector } from "react-redux";
import { RootState } from "../..";

export interface BatchProps {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  applicationStartDate: string;
  applicationEndDate: string;
}

export default function AllBatchesPage() {
  const tableColumns = ["Number", "Start Date", "End Date"];
  const data = useSelector((state: RootState) => state.batches);


  return (
    <div className="applicant-page-wrapper">
      <br />
      <Grid container spacing="sm" alignItems="flex-end">
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <h1>Batches</h1>
        </Grid>
      </Grid>
      <br />
      <div>
        <Table>
          <TableHead>
            <TableRow>
              {tableColumns.map((column, index) => (
                <TableCell key={index} align="left" component="th">
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(
              (batch, index: React.Key) => (
                <BatchRow key={index} batch={batch} />
              )
            )}
          </TableBody>
        </Table>
        <br />
      </div>
    </div>
  );
}
